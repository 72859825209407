import { Box, ButtonLink } from '@butcherbox/freezer'
import { Link } from 'gatsby'
import React from 'react'
import FeedbackForm from '~/components/FeedbackForm/FeedbackForm'
import { FeedbackFormContainer } from '~/components/FeedbackForm/FeedbackForm.ui'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { StickyBottom } from '~/components/StickyBottom/StickyBottom'
import useJustCookPosts from '~/hooks/useJustCookPosts'
import * as Styles from './SavedRecipesList.css'
import RecipeGrid from '~/components/RecipeList/RecipeGrid'

const SavedRecipes = ({ savedRecipeIds }: { savedRecipeIds: number[] }) => {
  //We are making API call to JustCook to get saved recipes posts with specified
  //recipeIds (i.e WordPress postId)
  const { data: savedRecipes, isLoading } = useJustCookPosts(
    savedRecipeIds.slice().reverse()
  )

  return (
    <>
      {/* funniness with hero header requires us to add padding to this wrapper  */}
      <Box className={Styles.RecipeGridWrap} paddingTop={isLoading ? 60 : 0}>
        <RecipeGrid
          cardWidth="large"
          columns={{ desktop: 3, tablet: 2, mobile: 1 }}
          gridGap={24}
          recipes={savedRecipes}
          recipesLoading={isLoading}
          showConfirmOnRemove
        />
      </Box>

      <StickyBottom>
        <ButtonLink component={Link} to="/account/recipes">
          explore more recipes
        </ButtonLink>
      </StickyBottom>
      <PanelBreak
        className={Styles.PanelBreakAboveFeedbackSection}
        intensity="light"
      />
      <FeedbackFormContainer>
        <FeedbackForm formId="saved-recipes-feedback-form" />
      </FeedbackFormContainer>
    </>
  )
}

export default SavedRecipes
