import HeroHeader from 'design/components/HeroHeader/HeroHeader'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const SavedRecipesHeader = () => {
  const heroImage = useStaticQuery(graphql`
    query {
      bg: contentfulImageSet(uniqueId: { eq: "saved_recipes_hero_image" }) {
        ...ImageSet
      }
    }
  `)

  return (
    <HeroHeader
      desktopOverlap={68}
      heading="Your Saved Recipes &amp; Tips"
      imageSet={heroImage.bg}
      textContainerWidth={35}
    />
  )
}

export default SavedRecipesHeader
