import type { RouteComponentProps } from '@reach/router'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import SavedRecipesHeader from './SavedRecipesHeader/SavedRecipesHeader'
import SavedRecipes from './SavedRecipesList/SavedRecipes'
import useSavedRecipeIds from '~/hooks/useSavedRecipeIds'
import RecipesEmptyState from '~/components/RecipesEmptyState/RecipesEmptyState'

const AccountSavedRecipes: React.FC<RouteComponentProps> = () => {
  const { isLoading, savedRecipeIds } = useSavedRecipeIds()

  return (
    <>
      <SavedRecipesHeader />

      {isLoading ? (
        <LoadingSpinner />
      ) : savedRecipeIds?.length ? (
        <SavedRecipes savedRecipeIds={savedRecipeIds} />
      ) : (
        <RecipesEmptyState
          buttonText={`explore more recipes`}
          message={`Select cuts from your upcoming box and get recommended recipes,
            tips & techniques for you to explore.`}
          title={`No saved recipes`}
          url="/account/recipes"
        />
      )}
    </>
  )
}

export default AccountSavedRecipes
