import { Lockup, Text, rem } from '@butcherbox/freezer'
import React from 'react'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'
import { TEST_ID } from '~/constants/cypress'
import * as Styled from './HeroHeader.styles'
import { HeroHeaderProps } from './HeroHeader.types'

const HeroHeader = ({
  desktopOverlap = 130,
  mobileOverlap = 135,
  textContainerWidth = 50,
  eyebrow,
  heading,
  bodyText,
  imageSet,
  additionalContent = () => null,
  ...props
}: HeroHeaderProps) => {
  return (
    <ImageSetBackground
      breakpoints={{ mobile: '(max-width: 767px)' }}
      data-cy={TEST_ID.HERO_BACKGROUND}
      imageSet={imageSet}
    >
      <Styled.HeroContainer
        desktop_overlap={desktopOverlap}
        height={bodyText ? rem(464) : '100%'}
        mobile_overlap={mobileOverlap}
        text_container_width={textContainerWidth}
        {...props}
      >
        <Lockup textAlign="left">
          {eyebrow && (
            <Text
              color="slate"
              component="span"
              data-cy={TEST_ID.HERO_SUBHEAD}
              variant="Eyebrow"
            >
              {eyebrow}
            </Text>
          )}
          <Text color="slate" component="h1" variant="DisplayOne">
            {heading}
          </Text>
          {bodyText && (
            <Text color="slate" component="h2" variant="Body1Regular">
              {bodyText}
            </Text>
          )}
          {additionalContent()}
        </Lockup>
      </Styled.HeroContainer>
    </ImageSetBackground>
  )
}

export default HeroHeader
