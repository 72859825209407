import { rem, styled } from 'design'
import { Box } from '@butcherbox/freezer'

interface HeroContainerProps {
  desktop_overlap: number
  mobile_overlap: number
  text_container_width: number
  height: number | string
}
export const HeroContainer = styled(Box)<HeroContainerProps>`
  height: ${(p) => p.height};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${rem(1300)};
  margin: 0 auto;
  padding-top: ${rem(135)};
  padding-bottom: ${(p) => rem(p.mobile_overlap + 42)};
  color: ${(p) => p.theme.colors.bb.slate};
  padding-left: ${rem(16)};
  padding-right: ${rem(16)};
  ${(p) => p.theme.media.tablet} {
    padding-top: ${rem(108)};
    padding-bottom: ${(p) => rem(p.desktop_overlap + 105)};
    & > * {
      max-width: 50%;
    }
  }
  ${(p) => p.theme.media.desktop} {
    & > * {
      max-width: ${(p) => p.text_container_width}%;
    }
  }
`
